.side__container {
    display: flex;
    flex-direction: column;
    width: 300px;
    background: #FFFFFF;
}

.logo__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    margin-bottom: 0px;
    background-color: white;
}

.menu-button {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 22px 0px 22px 46px;
    font-family: 'Lexend';
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #000000;
}

.header__container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.header-menu {
    border: none;
    background: none;
    margin-right: 24px;
}

.menu {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    position: absolute;
    z-index: 1;
    right: 10px;
    top: 73px;
    border-radius: 10px;
    border: 1px solid #909090;
}

.header-menu-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 140px;
    border-radius: 8px;
    text-decoration: none;
    padding: 15px;
    color: #2F3A4A;
    font-size: 17px;
    font-weight: 400;
}

@media(max-width: 1065px) {
    .side__container {
        width: 220px;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9F9FC;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-family: 'Lexend';
  color: #000000;
  margin: 0;
}

button {
  cursor: pointer;
  border: none;
  background: none;
  padding: 0px;
}

/* input, */
button,
a,
textarea {
  font-family: 'Lexend';
  -webkit-tap-highlight-color: transparent;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]+span:before {
  width: 15px;
  height: 15px;
  background: white;
  border: 1.5px solid white;
  content: "";
  display: inline-block;
  vertical-align: middle;
  margin: 0 11px 0 0;
  border-radius: 50%;
}

input[type="checkbox"]:checked+span:before {
  background: #3888FF;
  border: 1.5px solid #3888FF;
  box-shadow: inset 0px 0px 0px 1px #fff;
  color: #333;
  content: "";
  border-radius: 50%;
}

input[type="checkbox"]:focus+span::before {
  outline: 0;
}

input::placeholder {
  color: #292D32;
  opacity: 0.7;
}

#search::placeholder {
  color: #7E7E7E;
}
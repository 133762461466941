.custom-dropdown {
    width: 100%;
    z-index: 111;
}
.date_text {
    margin-left: 10px;
    font-weight: 500;
    color: #3D3C42;
}
.custom-dropdown-selection {
    background-color: #F5F6FA;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 15px;
}

.items-holder {
    border-radius: 6px;
    background-color: white;
    box-shadow: rgb(0 0 0 / 15%) 0px 5px 15px;
    position: absolute;
    top: 87%;
    width: 320px;
    right: 0;
    padding: 10px 12px;
    top: 50px;
}

.custom-dropdown .items-holder .dropdown-item {
    padding: 10px 10px;
    padding-top: 0px;
}

.calendar_range_icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 53px;
}

.custom-dropdown-selection::after {
    display: block;
    content: "";
    width: 7px;
    height: 7px;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    transform: rotate(45deg);
    position: absolute;
    right: 15px;
    top: 16px;
    transition: 0.2s;
}

.custom-dropdown-selection.visible::after {
    transform: rotate(-135deg);
    top: 22px;
    transition: 0.2s;
}

.calendar-icon {
    position: absolute;
    margin-top: -29px;
    width: 15px;
    margin-left: 7px;
    display: block;
}

.react-datepicker-ignore-onclickoutside {
    position: relative;
}

.btns-calendar {
    margin-top: 10px;
    width: 100%;
    border: none;
    background: #F5F6FA;
    padding: 12px;
    color: #3D3C42;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
}